import { hasSnackbarAccess } from "@/mixins/ui";
import { BATCH_UPDATE_BACKSTOCK } from "../../graphql";
import { batchMutationUpdate } from "../../graphql";

export default {
  inheritAttrs: false,
  mixins: [hasSnackbarAccess],

  props: {
    backstocks: {
      type: Array,
      default: () => [],
      required: true,
    },
  },

  data: () => ({
    loading: false,
  }),

  methods: {
    close() {
      setTimeout(this.reset, 1);
      return this.$emit("input", false);
    },
    complete() {
      setTimeout(this.reset, 1);
      return this.$emit("complete");
    },

    async save() {
      this.loading = true;
      try {
        await this.$apollo.mutate({
          mutation: BATCH_UPDATE_BACKSTOCK,
          variables: { ...this.variables },
          update: batchMutationUpdate,
        });
      } catch (error) {
        this.showSnackbar({
          text: `There was a problem updating backstocks: ${error}`,
        });
      }
      this.loading = false;
      this.complete();
    },
  },
};
