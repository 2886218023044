<template>
  <v-dialog v-bind="$attrs" persistent v-on="$listeners">
    <v-card>
      <v-container>
        <v-card-text>
          <h1 class="text-h5 mb-3 accent--text">
            {{ $t("backstock.app.confirmAddBinDialog.title") }}
          </h1>
          <section>
            {{
              $t("backstock.app.confirmAddBinDialog.infoMessage[0]", {
                binNumber: bin,
              })
            }}
            <br />
            <br />
            {{ $t("backstock.app.confirmAddBinDialog.infoMessage[1]") }}
          </section>
        </v-card-text>
      </v-container>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="ui" @click="close">
          {{ $t("backstock.app.confirmAddBinDialog.cancelButtonText") }}
        </v-btn>
        <v-btn text color="accent" @click="confirm">
          {{ $t("backstock.app.confirmAddBinDialog.okButtonText") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmAddBinDialog",
  inheritAttrs: false,
  props: {
    bin: {
      type: String,
      default: "",
    },
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
    confirm() {
      this.$emit("confirm", this.bin);
    },
  },
};
</script>
