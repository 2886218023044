<template>
  <v-dialog
    v-bind="$attrs"
    fullscreen
    transition="dialog-bottom-transition"
    v-on="$listeners"
  >
    <v-card>
      <v-toolbar color="ui lighten-4" extended flat>
        <v-btn icon @click="back">
          <v-icon v-text="`$vuetify.icons.back`" />
        </v-btn>
        <v-toolbar-title v-text="titleText" />
        <template #extension>
          <v-btn text color="accent" @click="showSetLocationDialog">
            {{ $t("backstock.app.scanModeDialog.locationButtonText") }}
          </v-btn>
          <v-btn text color="accent" @click="showSetNoteDialog">
            {{ $t("backstock.app.scanModeDialog.noteButtonText") }}
          </v-btn>
        </template>
      </v-toolbar>
      <template v-if="itemList.length">
        <v-list :key="renderKey" two-line>
          <template v-for="item in itemList">
            <BackstockListItem
              :key="item.id"
              :backstock="item"
              @show="selectBackstock"
            />
          </template>
        </v-list>
      </template>
      <template v-else>
        <div class="pa-3 ui--text">
          {{ $t("backstock.app.scanModeDialog.emptyBinsText") }}
        </div>
      </template>
    </v-card>
    <ScanModeSetLocationDialog
      v-model="setLocationDialog"
      :backstocks="items"
      @complete="onLocationComplete"
    />
    <ScanModeSetNoteDialog
      v-model="setNoteDialog"
      :backstocks="items"
      @complete="onNoteComplete"
    />
  </v-dialog>
</template>

<script>
import BackstockListItem from "../BackstockListItem";
import ScanModeSetLocationDialog from "./ScanModeSetLocationDialog";
import ScanModeSetNoteDialog from "./ScanModeSetNoteDialog";

import { backstocksQuery } from "../../mixins/backstock.js";
import { hasSnackbarAccess } from "@/mixins/ui";

export default {
  components: {
    BackstockListItem,
    ScanModeSetLocationDialog,
    ScanModeSetNoteDialog,
  },
  mixins: [hasSnackbarAccess, backstocksQuery],
  inheritAttrs: false,

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    storeId: { type: String, default: "" },
  },

  data: () => ({
    error: false,
    setLocationDialog: false,
    setNoteDialog: false,
    renderKey: 0,
    callbacks: [],
  }),

  computed: {
    backstockQueryVariables() {
      return {
        storeId: this.storeId,
      };
    },
    titleText() {
      return this.$tc("backstock.app.scanModeDialog.title", this.items.length);
    },
    // `items` is an array of IDs, so we can read the backstocks from the cache.
    // This function returns the cached backstocks (so we get view updates after mutations)
    itemList() {
      return (
        this.items.length &&
        this.items
          .map((id) =>
            this.allBackstocks.find((backstock) => backstock.id === id)
          )
          .filter(Boolean)
      );
    },
    backstocks() {
      return this.allBackstocks;
    },
  },

  methods: {
    selectBackstock(backstock) {
      if (backstock && backstock.id) {
        this.$router.push({
          name: "backstock_edit",
          params: { backstockId: backstock.id },
          preserveQuery: true,
        });
      }
    },
    back() {
      this.$emit("input", false);
    },
    showSetLocationDialog() {
      this.setLocationDialog = true;
    },
    showSetNoteDialog() {
      this.setNoteDialog = true;
    },
    onLocationComplete() {
      this.setLocationDialog = false;
      this.showSnackbar({
        text: this.$tc(
          "backstock.app.scanModeDialog.updateLocationSuccessMessage",
          this.items.length
        ),
      });
      this.renderKey++;
    },
    onNoteComplete() {
      this.setNoteDialog = false;
      this.showSnackbar({
        text: this.$tc(
          "backstock.app.scanModeDialog.updateNoteSuccessMessage",
          this.items.length
        ),
      });
      this.renderKey++;
    },
  },
};
</script>
