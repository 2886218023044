<template>
  <VxDialogSmall :title="title" v-bind="$attrs" v-on="$listeners">
    <template slot="body">
      <v-textarea
        v-model="note"
        :label="$t('backstock.app.scanModeSetNoteDialog.noteLabel')"
        filled
      />
    </template>
    <template slot="actions">
      <v-spacer />
      <v-btn color="ui" text @click="close">
        {{ $t("backstock.app.scanModeSetNoteDialog.cancelButtonText") }}
      </v-btn>
      <v-btn
        :disabled="disabled"
        :loading="loading"
        color="accent"
        text
        @click="save"
      >
        {{ $t("backstock.app.scanModeSetNoteDialog.okButtonText") }}
      </v-btn>
    </template>
  </VxDialogSmall>
</template>

<script>
import ActionDialogBase from "./Base";
import VxDialogSmall from "@/components/vx/VxDialogSmall";

export default {
  components: {
    VxDialogSmall,
  },
  extends: ActionDialogBase,
  data: () => ({
    note: undefined,
  }),
  computed: {
    disabled() {
      return !this.note || !this.note.length;
    },
    title() {
      return this.$tc(
        "backstock.app.scanModeSetNoteDialog.title",
        this.backstocks.length
      );
    },
    variables() {
      return {
        input: {
          backstockIds: this.backstocks,
          note: this.note,
        },
      };
    },
  },

  methods: {
    reset() {
      this.note = "";
    },
  },
};
</script>
