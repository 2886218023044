<template>
  <VxDialogSmall :title="title" v-bind="$attrs" v-on="$listeners">
    <template slot="body">
      <LocationSelect
        v-model="newLocation"
        no-validate
        :label="$t('backstock.app.scanModeSetLocationDialog.locationLabel')"
      />
    </template>
    <template slot="actions">
      <v-spacer />
      <v-btn color="ui" text @click="close">
        {{ $t("backstock.app.scanModeSetLocationDialog.cancelButtonText") }}
      </v-btn>
      <v-btn
        :disabled="disabled"
        :loading="loading"
        color="accent"
        text
        @click="save"
      >
        {{ $t("backstock.app.scanModeSetLocationDialog.okButtonText") }}
      </v-btn>
    </template>
  </VxDialogSmall>
</template>

<script>
import ActionDialogBase from "./Base";
import LocationSelect from "../LocationSelect";
import VxDialogSmall from "@/components/vx/VxDialogSmall";

import get from "lodash/get";

export default {
  components: {
    LocationSelect,
    VxDialogSmall,
  },

  extends: ActionDialogBase,

  data: () => ({
    newLocation: undefined,
  }),

  computed: {
    disabled() {
      return !this.newLocation || !this.newLocation.id;
    },
    title() {
      return this.$tc(
        "backstock.app.scanModeSetLocationDialog.title",
        this.backstocks.length
      );
    },
    variables() {
      return {
        input: {
          backstockIds: this.backstocks,
          locationId: get(this.newLocation, "id"),
        },
      };
    },
  },

  methods: {
    reset() {
      this.newLocation = undefined;
    },
  },
};
</script>
