<script>
import ScanModeDialog from "./components/ScanModeDialog";
import InvalidScanDialog from "./components/InvalidScanDialog";
import ConfirmAddBinDialog from "./components/ConfirmAddBinDialog";
import { mountsBarcodeHandlerWhenActive } from "@/mixins/barcode";
import { backstocksQuery, binsQuery } from "./mixins/backstock.js";
import { union, get } from "lodash";

export default {
  name: "BackstockScanListWrapper",
  components: {
    ScanModeDialog,
    InvalidScanDialog,
    ConfirmAddBinDialog,
  },
  mixins: [mountsBarcodeHandlerWhenActive, backstocksQuery, binsQuery],
  props: {
    storeId: { type: String, default: "" },
  },
  data() {
    return {
      scanMode: false,
      binScans: [],
      // `lastScan` is used for storing a scan that doesn't yet
      // need to go into `binScans`, (e.g., a valid bin that's not yet logged in)
      lastScan: undefined,
      invalidScanDialog: false,
      confirmAddBinDialog: false,
    };
  },
  computed: {
    backstocks() {
      const list = this.allBackstocks.filter(
        (b) =>
          (!this.categoryId || b.category.id === this.categoryId) &&
          (!this.locationId || b.location.id === this.locationId) &&
          (!this.search || b.searchKey.match(this.searchRegex))
      );
      const lastIndex = list.length - 1;
      const lastBackstock = list[lastIndex];
      list[lastIndex] = { ...lastBackstock, isLast: true };
      return list;
    },
    backstockBinNames() {
      return this.allBackstocks.map(({ bin }) => bin.name.toLowerCase());
    },
    binNames() {
      return this.bins
        .map(({ name }) => name && name.toLowerCase())
        .filter(Boolean);
    },
    allBinNames() {
      return union(this.binNames, this.backstockBinNames);
    },
    backstockQueryVariables() {
      return {
        storeId: this.storeId,
      };
    },
  },
  watch: {
    scanMode: function (newScanMode) {
      // Clear bin scans when existing scan dialog
      if (!newScanMode) {
        this.binScans = [];
      }
    },
  },
  methods: {
    handleBarcode({ detail: { data: v } }) {
      // Guard: If either of the disruptive dialogs are present, return early
      if ([this.confirmAddBinDialog, this.invalidScanDialog].some(Boolean)) {
        return;
      }

      // Sanitize the input value
      const binName = (v && v.length && v.trim()) || undefined;

      // Guard
      if (!binName) return;

      this.lastScan = binName;

      const validBin = this.allBinNames.includes(binName.toLowerCase());
      if (!validBin) return (this.invalidScanDialog = true);

      const binIsLoggedIn = this.backstockBinNames.includes(
        binName.toLowerCase()
      );

      if (!binIsLoggedIn) {
        // If the bin is not logged in

        if (this.binScans.length) {
          // If this is NOT the first bin in the scan list, show the confirm dialog

          this.showConfirmAddBinDialog();
          return;
        } else if (!this.binScans.length) {
          // If this IS the first bin in the scan list, show the add bin dialog

          // Find the bin via name
          const foundBin = this.findBinByName(binName);
          const routeToNavigateTo = {
            name: "backstock_new",
            preserveQuery: true,
            query: {
              binId: foundBin.id,
            },
          };
          this.$router.push(routeToNavigateTo);
          return;
        }
      } else {
        // If the bin is logged in, that is, it is found
        // Find the existing backstock and add it to the scan list
        const backstock = this.findBackstockByBinName(binName);
        if (this.binScans.indexOf(backstock.id) < 0) {
          this.binScans = [...this.binScans, backstock.id];

          // If we just added the first item to scan list - show the item edit dialog.
          // If we added the second, third, etc.. - show the scan list
          if (this.binScans.length === 1) {
            this.$router.push({
              name: "backstock_edit",
              params: {
                backstockId: backstock.id,
              },
              preserveQuery: true,
            });
          } else {
            this.scanMode = true;

            // If we are on backstock edit page - hide the dialog
            if (this.$route.name === "backstock_edit") {
              this.$router.replace({
                name: "backstock_home",
                preserveQuery: true,
              });
            }
          }
        }
      }
    },
    findBinByName(binName) {
      return this.bins.find(
        (bin) => bin.name.toUpperCase() === binName.toUpperCase()
      );
    },
    findBackstockByBinName(binName) {
      return this.backstocks.find(
        (backstock) =>
          backstock.bin.name.trim().toUpperCase() === binName.toUpperCase()
      );
    },
    showConfirmAddBinDialog() {
      this.confirmAddBinDialog = true;
    },
    onAddConfirm(binName) {
      const foundBin = this.findBinByName(binName);
      const routeToNavigateTo = {
        name: "backstock_new",
        preserveQuery: true,
        query: {
          binId: foundBin.id,
        },
      };
      this.$router.push(routeToNavigateTo);
    },
  },
  // TODO - see if this is needed still
  beforeRouteUpdate(to, from, next) {
    if (from.name === "backstock_new" && this.scanMode) {
      // If we returned from new backstock route, and scan list is opened,
      // it means that the last scan was added to backstocks.
      this.confirmAddBinDialog = false;
      this.handleBarcode({ detail: { data: this.lastScan } });
    }

    next();
  },
};
</script>

<template>
  <div>
    <ScanModeDialog v-model="scanMode" :items="binScans" :store-id="storeId" />
    <InvalidScanDialog v-model="invalidScanDialog" />
    <ConfirmAddBinDialog
      v-model="confirmAddBinDialog"
      :bin="lastScan"
      @confirm="onAddConfirm"
    />
  </div>
</template>
